<template>
  <div class="footer-wrap">
    <div class="copyright">Copyright ©2018-©2019 enterprise-admin finance web</div>
  </div>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped lang="scss">
.footer-wrap{
  .copyright{
    font-size: .7rem;
  }
}
</style>
