<template>
  <div
    class="aside-wrap display--flex"
    :style="{
      width: isPackup ? '104px' : 'auto',
    }"
  >
    <div class="aside-header">
      <div class="admin-logo">
        <img src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="login-info" v-if="endLoadPersonData">
        <div class="user-avator">
					<img
					  :src="this.$store.state.login.userinfo.photourl == null
					      ?	require('@/assets/image/deliLogin.png')
								: $url.upload + this.$store.state.login.userinfo.photourl 
					  "
						@click="avatorbtn"
						title="点击修改图片"
					  alt=""
					/>
          <el-badge :value="noReadMsgRes.totalcount" class="item" style="cursor: pointer;" @click.native="onGoNotice"> </el-badge>
        </div>
        <div class="username display--flex">
          {{ $store.state.login.userinfo.realname }}
          <div class="user-options">
            <el-dropdown>
              <div class="dropdown-wrap">
                <span class="el-dropdown-link">
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item divided @click.native="onLogout"
                    >退出登录</el-dropdown-item
                  >

                  <el-dropdown-item class="clearfix" @click.native="onGoNotice">
                    未读消息
                    <el-badge class="mark" :value="noReadMsgRes.totalcount" />
                  </el-dropdown-item>
									<el-dropdown-item class="clearfix" @click.native="onPassword">
									  设置密码
									</el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-w">
      <!-- 一级菜单 - w -->
      <div class="main-menu bg-2B5A95">
        <div
          class="main-menu-i flex_jc_ai--center fd--column"
          :class="
            $store.state.aside.mainMenuId === item.id
              ? 'avtive-main-menu'
              : 'c-fff'
          "
          v-for="(item, i) in $store.state.aside.mainMenu"
          :key="i"
          @click="onMainMenuClick(i, item)"
        >
          <div class="menu-icon">
            <img :src="mainMenuIcon[i]" alt="" />
          </div>
          <div class="menu-name">{{ item.name }}</div>
        </div>
      </div>
      <!-- 二级菜单 - w -->
      <div
        class="second-menu"
        :class="isPackup ? 'packUp' : ''"
        :style="{
          width: secondWidth + 'px',
          'margin-left': isPackup ? '-' + secondWidth + 'px' : 0,
          transition: 'all 500ms',
        }"
      >
        <div
          class="second-menu-i"
          v-for="(secondItem, i) in $store.state.aside.secondMenu"
          :key="i"
        >
          <div
            @click="onSecondMenuClick(i, secondItem)"
            class="second-menu-title flex_jc-between ai--center"
            :class="
              $store.state.aside.secondMenuId === secondItem.id
                ? 'avtive-second-menu'
                : ''
            "
          >
            <span class="second-menu-name">{{ secondItem.name }} </span>
            <i
              :class="
                $store.state.aside.secondMenuId === secondItem.id &&
                repeatSwitch
                  ? 'el-icon-arrow-down'
                  : 'el-icon-arrow-right'
              "
            ></i>
          </div>
          <!-- 三级菜单 -->
          <el-collapse-transition>
            <div
              class="third-menu"
              v-show="
                $store.state.aside.secondMenuId === secondItem.id &&
                repeatSwitch
              "
            >
              <div
                class="third-menu-i"
                :class="
                  $store.state.aside.thirdMenuId === thirdItem.id &&
                  repeatSwitch &&
                  $store.state.aside.thirdMenu.length
                    ? 'avtive-third-menu'
                    : ''
                "
                v-for="(thirdItem, thirdI) in $store.state.aside.thirdMenu"
                :key="thirdI"
                @click="onThirdMenuClick(thirdI, thirdItem)"
              >
                {{ thirdItem.name }}
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>

    <!-- 收起 -->
    <div
      class="pack-up flex_jc_ai--center"
      :style="{ right: isPackup ? '-20px' : '-20' + 'px' }"
      @click="onPackupClick"
      style=""
    >
      <i :class="isPackup ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
    </div>
		<!--修改头像-->
		<el-dialog
		  class="btn-2b5a95"
		  width="1000px"
		  :title="dialogTitle"
		  :visible.sync="showAddModal"
		  v-if="showAddModal"
		  append-to-body
		>
				<div class="cropper-content">
				    <div class="cropper-box">
				      <div class="cropper">
				        <vue-cropper
				            ref="cropper"
				            :img="option.img"
				            :outputSize="option.outputSize"
				            :outputType="option.outputType"
				            :info="option.info"
				            :canScale="option.canScale"
				            :autoCrop="option.autoCrop"
				            :autoCropWidth="option.autoCropWidth"
				            :autoCropHeight="option.autoCropHeight"
				            :fixed="option.fixed"
				            :fixedNumber="option.fixedNumber"
				            :full="option.full"
				            :fixedBox="option.fixedBox"
				            :canMove="option.canMove"
				            :canMoveBox="option.canMoveBox"
				            :original="option.original"
				            :centerBox="option.centerBox"
				            :height="option.height"
				            :infoTrue="option.infoTrue"
				            :maxImgSize="option.maxImgSize"
				            :enlarge="option.enlarge"
				            :mode="option.mode"
				            @realTime="realTime"
				            @imgLoad="imgLoad">
				        </vue-cropper>
				      </div>
				      <div class="footer-btn">
				        <div class="scope-btn">
									<el-upload
									 class="upload-demo"
									 :action="$store.state.global.baseUrl"
										multiple
									 :show-file-list="false"
									 :before-upload="beforeUpload"
									 :http-request="uploadFile">
									  <el-button 
											size="mini" 
											type="primary" 
											class="upload-btn"
											style="margin-right: 10px;"
											icon="el-icon-upload"
											>
											选择图片
										</el-button>
									</el-upload>
				          <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
				          <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
				          <el-button size="mini" type="danger" plain @click="rotateLeft">左旋转</el-button>
				          <el-button size="mini" type="danger" plain @click="rotateRight">右旋转</el-button>
				        </div>
				        <div class="upload-btn">
				          <el-button size="mini" type="success" @click="uploadImg()">提交上传</el-button>
				        </div>
				      </div>
				    </div>
				    <!--预览效果图-->
				    <div class="show-preview">
				      <div :style="previews.div" class="preview">
				        <img :src="previews.url" :style="previews.img">
				      </div>
				    </div>
				  </div>
		</el-dialog>
		<!-- 设置密码 -->
		<el-dialog
		  class="btn-2b5a95"
			v-dialogDrag
		  :title="dialogTitle"
		  width="25%"
		  :visible.sync="showSetpwdModal"
			:close-on-click-modal='false'
			v-if="showSetpwdModal"
		  append-to-body
		>
		  <el-form
		    ref="dynamicValidateForm"
		    :model="dynamicValidateForm"
		    :rules="rules"
		    label-width="100px"
		    class="form-item-w-100"
		  >
		    <el-form-item label="真实姓名">
		      <el-input
		        v-model.trim="dynamicValidateForm.realname"
		        disabled
		        autocomplete="off"
						clearable
		      />
		    </el-form-item>
		    <el-form-item label="员工编号">
		      <el-input
		        v-model.trim="dynamicValidateForm.code"
		        disabled
		        autocomplete="off"
						clearable
		      />
		    </el-form-item>
		    <el-form-item label="密码" prop="newpwd">
		      <el-input
		        v-model.trim="dynamicValidateForm.newpwd"
		        type="password"
		        autocomplete="off"
						clearable
		      />
		    </el-form-item>
		    <el-form-item label="确认密码" prop="comfirmPwd">
		      <el-input
		        v-model.trim="dynamicValidateForm.comfirmPwd"
		        type="password"
		        autocomplete="off"
						clearable
		      />
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="setPwd('dynamicValidateForm')"
		      >提交</el-button
		    >
		  </span>
		</el-dialog>
  </div>
</template>
 
<script>
import { VueCropper } from 'vue-cropper'
import { mapActions } from "vuex";
import {
  _module_getmodule,
  logout_logout,
	 _fileupload_save, 
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  setSession,
  getSession,
  debounce,
  getElementStyle,
} from "@/utils/util";
import { 
	notice_totalcount,
	user_updatephotourl,
	user_setpwdsave,
	} from "@/request/api/allChildrenProject";
import Cookies from "js-cookie";

export default {
	inject: ["reload"],
  created() {
    this.mainContent();
    this.noreadmsg()
		console.log(this.$store.state.login.userinfo,this.$store.state.login.userinfo.photourl, '更换头像在created')
  },
  mounted() {},
  data() {
		//密码表单规则
		var validatePass = (rule, value, callback) => {
		  if (value === "") {
		    callback(new Error("请输入密码"));
		  } else {
		    if (this.dynamicValidateForm.comfirmPwd !== "") {
		      this.$refs.dynamicValidateForm.validateField("comfirmPwd");
		    }
		    callback();
		  }
		};
		var validatePass2 = (rule, value, callback) => {
		  if (value === "") {
		    callback(new Error("请再次输入密码"));
		  } else if (value !== this.dynamicValidateForm.newpwd) {
		    callback(new Error("两次输入密码不一致!"));
		  } else {
		    callback();
		  }
		};
    return {
      mainMenuIcon: [
        require("../../assets/image/main5.png"),
        require("../../assets/image/main1.png"),
        require("../../assets/image/main1.png"),
        require("../../assets/image/main1.png"),
        require("../../assets/image/main1.png"),
        require("../../assets/image/main2.png"),
        require("../../assets/image/main3.png"),
        require("../../assets/image/main4.png"),
      ],
			photourl: "",
      repeatSwitch: true, //是否重复打开二级菜单
      secondTempSwitch: 0, //二级临时开关
      secondWidth: 150,
      isPackup: false, //是否收起
      noReadMsgRes:null,
      endLoadPersonData:false,
			showAddModal: false, //头像
			dynamicValidateForm: {}, //表单
			showSetpwdModal: false, //设置密码
			rules: {
				newpwd: [
				  {
				    trigger: "blur",
				    validator: validatePass,
						required: true 
				  },
				],
				comfirmPwd: [
				  {
				    trigger: "blur",
				    validator: validatePass2,
						required: true 
				  },
				],
			}, //表单规则
			dialogTitle: "", //对话框标题
			fds: new FormData(),
			previews: {},
			option:{
				img: '',             //裁剪图片的地址
				outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
				outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
				info: true,          //图片大小信息
				canScale: true,      //图片是否允许滚轮缩放
				autoCrop: true,      //是否默认生成截图框
				autoCropWidth: 230,  //默认生成截图框宽度
				autoCropHeight: 150, //默认生成截图框高度
				fixed: true,         //是否开启截图框宽高固定比例
				fixedNumber: [1.53, 1], //截图框的宽高比例
				full: false,         //false按原比例裁切图片，不失真
				fixedBox: true,      //固定截图框大小，不允许改变
				canMove: false,      //上传图片是否可以移动
				canMoveBox: true,    //截图框能否拖动
				original: false,     //上传图片按照原始比例渲染
				centerBox: false,    //截图框是否被限制在图片里面
				height: true,        //是否按照设备的dpr 输出等比例图片
				infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
				maxImgSize: 3000,    //限制图片最大宽度和高度
				enlarge: 1,          //图片根据截图框输出比例倍数
				mode: '230px 150px'  //图片默认渲染方式
			}
			
    };
  },
  methods: {
    ...mapActions("tab", ["addTab"]),
    ...mapActions("aside", [
      "mainContent",
      "mainNav",
      "mainmenu",
      "thirdRouteJump",
      "getMenuItem",
    ]),
		...mapActions("global", ["onExtendClick"]),
		//更换头像
		avatorbtn() {
			this.dialogTitle = "更换头像";
			this.showAddModal = true;
		},
    //退出登录 click
    onLogout() {
      this.$store.state.aside.secondMenu = [];
      this.logout();
			console.log(this.$store.state, '这个是什么',this.$store.state.login, '哈哈---',
			this.$store.state.login, '这个是什么东西啊---',
			 this.$store.state.login.cooperation, '48啊哈')
    },
    //退出登录 api
    logout() {
      myRequest({
        url: logout_logout,
      }).then((res) => {
        if (res.data.code === "200") {
          sessionStorage.clear();
          Cookies.remove("Admin-Token");
          this.$router.push("/login");
        }
      });
    },

    //一级菜单 click
    onMainMenuClick(idx, item) {
      this.$store.state.aside.mainMenuId = item.id;
      if (item.id !== getSession("mainMenuId")) {
        setSession("mainMenuId", item.id);
        this.mainNav(item.id);
      }
    },
    //二级菜单 click
    onSecondMenuClick(idx, item) {
      this.$store.state.aside.secondMenuId = item.id;
      this.checkIndexRepeat(
        this.$store.state.aside.secondMenuId,
        "repeatSwitch",
        "secondTempSwitch"
      );
      if (this.$store.state.aside.secondMenuId !== getSession("secondMenuId")) {
        setSession("secondMenuId", item.id);
        this.mainmenu(item.id);
      }
      this.$store.state.aside.secondMenuItem = item;
    },
    //三级菜单 click
    onThirdMenuClick(idx, item) {
      this.getMenuItem({
        id: item.id,
        list: ["thirdMenuId", "secondMenuId", "mainMenuId"],
      });
      this.$store.state.aside.thirdMenuItem = item;
      this.$store.state.tab.editableTabsValue = item.url;
      this.thirdRouteJump();
    },
    //收起 click
    onPackupClick() {
      this.isPackup = !this.isPackup;
      this.$emit("onPackupClick", this.secondWidth, this.isPackup);
    },
    //检验当前索引是否重复打开
    checkIndexRepeat(current, _switch, tempIndex) {
      this[_switch] = this[tempIndex] === current ? !this[_switch] : true;
      this[tempIndex] = current;
    },

    //未读信息 api
    noreadmsg(){
      myRequest({
        url:notice_totalcount,
        data:{
        }
      }).then(res=>{
        if(res.data.code==='200'){
          this.noReadMsgRes = res.data.data
          this.endLoadPersonData = true
        }
      })
    },
    //点击消失标记 click
    onGoNotice(){
      this.getMenuItem({
        id: this.noReadMsgRes.module.id,
        list: ["thirdMenuId", "secondMenuId", "mainMenuId"],
      });//每当改变最终菜单激活项都需要调 getMenuItem
      this.$router.push({
        name:'notice',
        params: {
          readflag: 0,
        },
      })
    },
		
		//设置密码
		onPassword() {
			this.dialogTitle = "设置密码";
			this.showSetpwdModal = true;
			this.dynamicValidateForm = {
			  newpwd: "",
			  comfirmPwd: "",
			};
			this.$set(
			  this.dynamicValidateForm,
			  "id",
			  this.$store.state.login.userinfo.id
			);
			this.$set(
			  this.dynamicValidateForm,
			  "code",
			  this.$store.state.login.userinfo.code
			);
			this.$set(
			  this.dynamicValidateForm,
			  "realname",
			  this.$store.state.login.userinfo.realname
			);
		},
		//设置密码 api
		setPwd(dynamicValidateForm) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
		          url: user_setpwdsave,
		          data: this.dynamicValidateForm,
		        },
		        {
							isRefTableList: true,
							that: this,
							modal: "showSetpwdModal",
		        }).then((res) => {
		    if (res.data.code === "200") {
						this.showSetpwdModal = false;
		    }
		  });
		    }
		  });
		},
		 //初始化函数
		    imgLoad (msg) {
		      console.log("工具初始化函数====="+msg)
		    },
		    //图片缩放
		    changeScale (num) {
		      num = num || 1
		      this.$refs.cropper.changeScale(num)
		    },
		    //向左旋转
		    rotateLeft () {
		      this.$refs.cropper.rotateLeft()
		    },
		    //向右旋转
		    rotateRight () {
		      this.$refs.cropper.rotateRight()
		    },
		    //实时预览函数
		    realTime (data) {
		      this.previews = data
		    },
		    //选择图片
		    	beforeUpload(file) {
						if (file.type.indexOf("image/") == -1) {
							this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
						} else {
							//转化为blob
							let reader = new FileReader()
							reader.readAsDataURL(file);
							reader.onload = () => {
							  this.option.img = reader.result;
							}
						}
		      
		    },
				//上传文件 upload
				uploadFile() {

				},
		    //上传图片
		    uploadImg() {
					this.$refs.cropper.getCropBlob(data => {
					  let formData = new FormData();
					  formData.append("upfile", data, "DX.jpg");
						myRequest({
						  method: "post",
						  url: user_updatephotourl,
						  data: formData,
						}).then((res) => {
						  if (res.data.code === "200") {
						    this.fds = new FormData();
						    this.$store.state.login.userinfo.photourl = res.data.url;
								this.showAddModal = false;
						  }
						});
					});
		    }
  },
};
</script>
 
<style lang="scss">
	.cropper-content{
	  display: flex;
	  display: -webkit-flex;
	  justify-content: flex-end;
	  .cropper-box{
	    flex: 1;
	    width: 100%;
	    .cropper{
	      width: auto;
	      height: 300px;
	    }
	  }
	
	  .show-preview{
	    flex: 1;
	    -webkit-flex: 1;
	    display: flex;
	    display: -webkit-flex;
	    justify-content: center;
	    .preview{
	      overflow: hidden;
	      border:1px solid #67c23a;
	      background: #cccccc;
	    }
	  }
	}
	.footer-btn{
	  margin-top: 30px;
	  display: flex;
	  display: -webkit-flex;
	  justify-content: flex-end;
	  .scope-btn{
	    display: flex;
	    display: -webkit-flex;
	    justify-content: space-between;
	    padding-right: 10px;
	  }
	  .upload-btn{
	    flex: 1;
	    -webkit-flex: 1;
	    display: flex;
	    display: -webkit-flex;
	    justify-content: center;
	  }
	  .btn {
	    outline: none;
	    display: inline-block;
	    line-height: 1;
	    white-space: nowrap;
	    cursor: pointer;
	    -webkit-appearance: none;
	    text-align: center;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    outline: 0;
	    -webkit-transition: .1s;
	    transition: .1s;
	    font-weight: 500;
	    padding: 8px 15px;
	    font-size: 12px;
	    border-radius: 3px;
	    color: #fff;
	    background-color: #409EFF;
	    border-color: #409EFF;
	    margin-right: 10px;
	  }
	}
	
	
	
	
	
	
	
.avtive-main-menu {
  background-color: #fff;
}
.avtive-second-menu {
  color: #2b5a95;
}
.avtive-third-menu {
  background: #eaf4ff;
}
.packUp {
  overflow: hidden;
  right: 0 !important;
}
.right--20 {
  right: -20px !important;
}

.aside-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .admin-logo {
    & > img {
      width: 100%;
    }
  }
}
.login-info {
  margin: 10px 0;
  font-size: 14px;
  .user-avator {
		cursor: pointer;
    & > img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
			object-fit: cover;
    }
  }
}
.aside-wrap {
  position: relative;
  z-index: 200;
  height: 100%;
  flex-direction: column;
  scrollbar-width: none; //火狐
  &::-webkit-scrollbar {
    display: none; //谷歌
  }

  -ms-overflow-style: none; //ie
  .pack-up {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 50px;
    margin: auto;
    background-color: #ccc;
    z-index: 1;
    transition: all 500ms;
    & > i {
      font-size: 20px;
      color: #fafafa;
    }
  }
}
.menu-w {
  overflow: auto;
  display: flex;
}
.main-menu {
  overflow-y: auto;
  position: relative;
  padding: 0 0 50px 10px;
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  .main-menu-i {
    padding: 12px 26px 12px 12px;
    margin: 20px 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    cursor: pointer;
    .menu-icon {
      width: 45px;
      height: 45px;
    }
    .menu-name {
      white-space: nowrap;
      padding: 5px 0 0 0;
      font-size: 14px;
    }
  }
}
.second-menu {
  overflow-y: auto;
  // position: absolute;
  top: 0;
  height: 100%;
  padding: 15px 10px;
  background-color: #fff;
  transition: all 500ms;
  &::-webkit-scrollbar {
    display: none;
  }
  .second-menu-i {
    margin: 0 0 20px 0;
    font-size: 14px;
    .second-menu-title {
      width: 100%;
      white-space: nowrap;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.third-menu {
  .third-menu-i {
    margin: 20px 0;
    padding: 3px 5px;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
  }
}
</style>