<template>
  <div class="home">
    <!-- <bn-header /> -->
    <bn-aside @onPackupClick="onPackupClick" />

    <div class="bn-main">
      <div
        class="bn-main-c"
        :style="{ 'margin-left': isPackup ? 0 : 0 + 'px' }"
      >
        <nav class="bn-nav">
          <bn-nav />
        </nav>
        <div class="main-c-w">
					<MainContent />
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { mapActions } from "vuex";
import BnAside from "../../components/home/Aside.vue";
import BnHeader from "../../components/home/Header.vue";
import MainContent from "../../components/home/Main.vue";
import Footer from "../../components/home/Footer.vue";
import BnNav from "../../components/home/Nav.vue";
import { getSession } from "@/utils/util";
 
export default {
  name: "home",
  components: {
    BnAside,
    BnHeader,
    MainContent,
    Footer,
    BnNav,
  },
  mounted() {
    this.getLoginUserAuth(getSession("user") ? getSession("user").id : "");
    
    //登录默认显示 业务管理
    this.$store.state.aside.mainMenuId = 32204
    this.addTab({
      tabId: 1,
      tabTitleName: '首页',
      tabRouteName: 'welcome',
      closable:false
    });


  },
  created() {},
  data() {
    return {
      secondWidth: 150,
      isPackup: false,
    };
  },
  methods: {
    ...mapActions("tab", ["addTab"]),
    ...mapActions("login", ["getLoginUserAuth"]),
    onPackupClick(secondW, isPackup) {
      this.secondWidth = secondW;
      this.isPackup = isPackup;
    },
  },
};
</script>
 
 
<style lang="scss">
@import "../../assets/css/base.css";
input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  padding: 0 10px;
}
 
.home {
  display: flex;
  // flex-direction: column;
  width: 100%;
  height: 100%;
  .bn-nav {
  }
}
 
.bn-main {
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
}
//关键
.bn-main-c {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 0 0 20px;
  transition: margin 500ms;
  background-color: #f4f4f4;
  &::-webkit-scrollbar {
    display: none; //谷歌
  }
 
  -ms-overflow-style: none; //ie
}
 
.is-vertical {
  display: flex;
  flex-direction: column;
}
 
.main-c-w {
  height: 100%;
  margin: -5px 0 0 0;
}

.menu-w,.bn-main{
  &::-webkit-scrollbar {
    display: none; //谷歌
  }
}
 
.el-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-left: 256px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #eeeeee;
  text-align: center;
  z-index: 400;
}
</style>