<template>
  <div class="main-wrap">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>
export default {
  components: {},

  mounted() {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss">
.main-wrap {
  position: relative;
  overflow: scroll;
  height: 100%;
  background-color: #fff;
  z-index: 310;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 3px;
  }
}
</style>