<template>
  <div class="header-wrap flex_ai--center jc--space-between">
    <div class="admin-title-w display--flex">
      <div class="admin-logo">
        <img src="@/assets/image/logo.png" alt="" />
      </div>
    </div>
    <div class="header-r">
      <div class="userinfo-w flex_ai--center">
        <div class="user-avator">avator</div>
        <div class="username">{{ $store.state.login.userinfo.realname }}</div>
        <div class="user-options">
          <el-dropdown>
            <div class="dropdown-wrap">
              <span class="el-dropdown-link">
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item divided @click.native="onLogout"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Cookies from "js-cookie";
import { logout_logout } from "@/request/api/public";
import { myRequest } from "@/request/index";
import { getSession } from "@/utils/util";
export default {
  created() {},
  mounted() {},
  props: {},
  data() {
    return {};
  },
	created: {
		//退出登录 click
		onLogout() {
		  this.logout();
		},
	},
  methods: {
    
    //退出登录 api
    logout() {
      myRequest({
        url: logout_logout,
      }).then((res) => {
        if (res.data.code === "200") {
          sessionStorage.clear();
					console.log(sessionStorage.clear(), '这个情空了吗 ')
          Cookies.remove("Admin-Token");
					console.log('Cookies.remove("Admin-Token");')
          this.$router.push("/login");
					console.log('this.$router.push("/login")')
        }
      });
    },
  },
};
</script>
 
<style scoped lang="scss">
.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  font-size: 18px;
  font-weight: bold;

  .el-dropdown {
    margin: 0 10px;
    font-size: 25px;
  }
}
</style>