<template>
  <div class="nav-table-wrap">
    <keep-alive>
      <el-tabs
        v-model="$store.state.tab.editableTabsValue"
        type="card"
        @tab-remove="removeTab"
        @tab-click="getTabRouter"
      >
        <el-tab-pane
          v-for="(item) in $store.state.tab.editableTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
          :closable="item.closable"
        >
          <span slot="label">
            <i class="tabIcon" :class="item.icon"></i> {{ item.title }}
          </span>
        </el-tab-pane>
      </el-tabs>
    </keep-alive>

    <div class="tab-dropdown">
      <el-dropdown
        :show-timeout="10"
        placement="top-end"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="currentTab"
            >关闭当前标签页</el-dropdown-item
          >
          <el-dropdown-item command="orderTabs"
            >关闭其他标签页</el-dropdown-item
          >
          <el-dropdown-item command="allTabs">关闭所有标签页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions("tab", ["removeTab", "getTabRouter"]),

    handleCommand(command) {
      this.removeTab(command);
    },
  },
};
</script>

<style lang="scss">
.nav-table-wrap {
  position: relative;

  .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap {
      display: flex;
      align-items: center;
      padding: 0 20px 0 15px;
      margin: 0 60px 0 -15px;

      span {
        font-size: 16px;
      }
    }

    .el-tabs__nav {
      background-color: #2b5a95;
      color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .el-tabs__item {
      height: 45px;
      color: #ccc;

      .tabIcon {
        padding-right: 6px;
      }
    }
    .is-active {
      font-weight: bold;
      color: #fff;
    }

    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      line-height: 37px;
    }
    .el-icon-close {
      border: 0 none !important;
    }
  }

  .tab-dropdown {
    position: absolute;
    right: 10px;
    top: 6px;

    .el-dropdown-link {
      font-size: 25px;
      padding: 0 5px 0 2px;
      background-color: #fff;
      border-radius: 4px;
    }
  }
}
</style>

